<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" icon color="gray-60" class="mr-n1">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item @click="$emit('openFilePreview', file)">
        <IconEye width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1">Open preview</v-list-item-title>
      </v-list-item>
      <v-list-item :to="go" target="_blank">
        <IconGlobe width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1">Open link</v-list-item-title>
      </v-list-item>
      <v-list-item @click="downloadFile">
        <IconDownload width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1">Download</v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!itemDisable"
        @click="file.showStyleInput = true">
        <IconPen width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1">Rename</v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!itemDisable"
        @click="deleteItems">
        <IconDelete width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1">Delete</v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "FilesVersionMenu",
  components: {
    IconGlobe: () => import('@/components/icons/IconGlobe'),
    IconEye: () => import('@/components/icons/IconEye'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconDelete: () => import('@/components/icons/IconDelete'),

    UiMenu,
    UiBtn,
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fileLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProjectPermissionData'
    ]),
    go() {
      return {
        name: 'DesignViewPage',
        params: {
          project_id: this.file.project.id,
        },
        query: {
          module_id: this.file.project_module.id,
          style_id: this.file.style.id,
          file_id: this.file.id,
          gallery_group_id: this.file.gallery_group_id,
        }
      }
    },
    itemDisable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.file.created_by === this.getAuthId ||
        this.getProjectPermissionData(this.file.project.id).roles.some(role => role === projectOwner || role === projectManager)
      );
    },
  },
  methods: {
    deleteItems() {
      if (!this.itemDisable) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete this file?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            this.$store.dispatch('closeModal', 'confirmModal');
            this.file.loading = true

            try {
              await this.$api.projectModuleDesign.deleteFiles(this.file.project.id, this.file.project_module.id, {items: [this.file.id]})
              this.$emit('deleteFile', this.file.id);
            } catch (error) {
              this.file.loading = false;
              console.error(error);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        }
      });
    },
    async downloadFile() {
      if (this.fileLoading || !this.file) return;
      this.fileLoading = true;

      const image = await fetch( this.file.file.download_url || this.file.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = this.file.file.download_file_name|| this.file.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
  }
}
</script>
