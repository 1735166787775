<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    @keydown.esc="$emit('onClose')"
    @click:outside="$emit('onClose')"
    :transition="''"
    :content-class="`preview-modal d-flex flex-column ${
      $store.state.SideMenu.mini ? 'is-mini' : ''
    } ${$vuetify.breakpoint.mdAndDown ? 'ml-0' : ''}`"
  >
    <div class="gray-80">
      <v-container style="padding: 9px 12px">
        <div class="preview-modal-header d-flex align-center px-2">
          <UiFileIcon width="24" class="gray-0--text" :extension="getFileName(file.file.original_name).ext" />

          <div
            class="d-flex ml-2 mr-4 overflow-hidden text-body-lg font-weight-medium gray-0--text"
            style="max-width: 260px"
          >
            <span class="text-no-wrap overflow-hidden text-overflow-ellipsis">
              {{ getFileName(file.file.original_name).name }}.
            </span>
            <span class="flex-shrink-0">{{
              getFileName(file.file.original_name).ext
            }}</span>
          </div>

          <div
            class="preview-modal-file-version relative d-flex align-center mr-6 rounded text-body font-weight-semi-bold gray-0--text"
          >
            V: {{ file.version }}
          </div>

          <v-divider vertical class="mr-6" />

          <UiBtn outlined color="gray-0" width="144" class="mr-2" @click="go">
            <IconExtLink width="12" class="mr-2" />
            Open in project
          </UiBtn>
          <UiBtn
            outlined
            color="gray-0"
            width="106"
            class="mr-2"
            :loading="downloadLoading"
            @click="downloadFile"
          >
            <IconDownload width="12" class="mr-2" />
            Download
          </UiBtn>
          <UiBtn outlined color="gray-0" width="106" class="mr-2" disabled>
            <IconShare width="12" class="mr-2" />
            Share
          </UiBtn>

          <UiBtn
            text
            plain
            width="auto"
            height="auto"
            color="gray-0"
            class="ml-auto"
            @click="$emit('onClose')"
            style="margin-top: 2px"
          >
            <IconCancel width="12" />
          </UiBtn>
        </div>
      </v-container>
    </div>

    <div
      class="preview-modal-file d-flex align-center justify-center fill-width fill-height pt-8 px-4"
    >
      <img
        v-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.file.original_name))"
        draggable="false"
        :src="file.file.url"
        alt=""
        class="preview-modal-file-image"
      />
      <XlsView
        v-if="$config.filesystem.fileTypes.xls.includes(getFileExtension(file.file.original_name))"
        :url="file.file.url"
        :name="file.file.original_name"
      />
      <iframe
        v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file.file.original_name))"
        :src="file.file.url"
        frameborder="0"
        class="preview-modal-file-iframe"
      />
    </div>
  </v-dialog>
</template>

<script>
import {getFileExtension, getFileName} from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";
import XlsView from "@/components/project/XlsView.vue";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: "PreviewModal",
  components: {
    UiFileIcon,
    IconExtLink: () => import("@/components/icons/IconExtLink"),
    IconDownload: () => import("@/components/icons/IconDownload"),
    IconShare: () => import("@/components/icons/IconShare"),
    IconCancel: () => import("@/components/icons/IconCancel"),
    UiBtn,
    XlsView,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: true,
      downloadLoading: false,
    };
  },
  methods: {
    getFileExtension,
    getFileName,
    go() {
      this.$router.push({
        name: "DesignViewPage",
        params: {
          project_id: this.file.project.id,
        },
        query: {
          module_id: this.file.project_module.id,
          style_id: this.file.style.id,
          file_id: this.file.id,
          gallery_group_id: this.file.gallery_group_id,
        },
      });
    },
    async downloadFile() {
      if (this.downloadLoading) return;
      this.downloadLoading = true;

      const image = await fetch(this.file.file.download_url || this.file.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement("a");
      anchor.href = imageURL;
      anchor.download = this.file.file.download_file_name || this.file.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.downloadLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .preview-modal {
  height: 100%;
  max-height: 100%;
  margin: 244px 0 0 184px;
  border-radius: 0;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(9.5px);

  &.is-mini {
    margin-left: 56px;
  }

  .preview-modal-file-version {
    height: 30px;
    padding: 1px 6px 0 6px;
    border: 1px solid var(--v-gray-0-base);
    background-color: var(--v-gray-60-base);
  }

  .preview-modal-file {
    height: calc(100% - 200px);
  }

  .preview-modal-file-image {
    height: 100%;
    max-height: 652px;
    max-width: 878px;
    object-fit: contain;
    user-select: none;
    touch-action: none;
    pointer-events: none;
  }

  .preview-modal-file-iframe {
    width: 100%;
    height: 100%;
    max-width: 878px;
  }
}
</style>
