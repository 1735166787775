<template>
  <FilesByVersions :key="this.$route.name"/>
</template>

<script>
import FilesByVersions from "@/views/files/by-versions/FilesByVersions";
export default {
  name: 'FilesByVersionsPage',
  components: {
    FilesByVersions,
  }
}
</script>
